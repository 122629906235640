import React from "react";
import "./styles.scss";

const Overview = () => {
  return (
    <div className="about-us-heroBg-wrap">
      <div className="about-us-hero-desc-wrap about-us-wrap ">
        <div className="about-us-hero-desc-wrap">
          <div className="about-us-hero-desc-innerWrap mobile-hide">
            <div className="about-us-hero-desc-border" />
          
            <p className="about-us-hero-desc">
              Kraftshala makes India’s most loved marketing & sales training programs with an Advocacy Score of 95%. We believe that education 
              becomes a lot more fruitful, and a lot more fun, when you get kickass practitioners, the real experts, to train emerging talent. Our programs are built on frameworks and 
              examples from the best in the industry and they are consistently updated with the latest, cutting-edge tools & learnings from the field. The INDUSTRYCreds™ certification that our 
              programs offer is on track to become the benchmark for hiring marketing and sales talent in India. <br/><br/>
              This is why S&M professionals from India’s top consumer companies (like Unilever, P&G, Nestlé, Yum! Brands etc) as well as students currently studying in the best business schools of the country (major IIMs, ISB, FMS, XLRI, SP Jain, IIFT, etc.) 
              find value in our programs (remember 95%?).<br/><br/>
              We are a team that is gunning to bring transformative changes to India’s higher education ecosystem. Welcome to the ride! :)
              Want to know more about us?
 
            </p>
            <div className="about-us-hero-desc-border" />
          </div>
          <div className="desktop-hide">
          
            <p className="about-us-hero-desc">
            Kraftshala makes India’s most loved marketing & sales training programs with an Advocacy Score of 95%. We believe that education becomes a lot more fruitful, and a lot more fun, when you get kickass practitioners, the real experts, to train emerging talent. 
            Our programs are built on frameworks and examples from the best in the industry and they are consistently updated with the latest, cutting-edge tools & learnings from the field. The INDUSTRYCreds™ certification that our programs offer is on track to become 
            the benchmark for hiring marketing and sales talent in India.<br/><br/>
            This is why S&M professionals from India’s top consumer companies (like Unilever, P&G, Nestlé, Yum! Brands etc) as well as students currently studying in the best business schools of the country 
            (major IIMs, ISB, FMS, XLRI, SP Jain, IIFT, etc.) find value in our programs (remember 95%?)<br/><br/>
            We are a team that is gunning to bring transformative changes to India’s higher education ecosystem. Welcome to the ride! :)
            Want to know more about us?

            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
