

export const investorsData = [
  {
    id: 1,
    image:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1svc790.webp",
    alt:
      "Nandu Nandkishore, Former Fortune 100 CEO Ex-Head of Asia, Oceania, Africa for Nestlé & Global CEO for Nestlé Nutrition",
    name: "nandu nandkishore",
    designation: `Former Fortune 100 CEO 
    Ex-Head of Asia, Oceania, Africa for 
    Nestlé & Global CEO for Nestlé Nutrition`,
  },
  {
    id: 2,
    image: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1svdxt9.webp",
    alt:
      "Pallav Jain, Co-CEO at Performics India MBA from IIM Ahmedabad batch of 2007",
    name: "pallav jain",
    designation: `Co-CEO at Performics India 
    MBA from IIM Ahmedabad batch of 2007`,
  },
  {
    id: 3,
    image:"https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1svgnqs.webp" ,
    alt:
      "Sarfaraz Khimani, Co-CEO at Performics India MBA from IIM Calcutta batch of 2008",
    name: "sarfaraz khimani",
    designation: `Co-CEO at Performics India 
    MBA from IIM Calcutta batch of 2008`,
  },
];
